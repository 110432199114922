// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/misc.tsx"
);
import.meta.hot.lastModified = "1715588348544.1648";
}
// REMIX HMR END

import { TFunction } from "i18next";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { MutableRefObject } from "react";

export function capitalize(str: string) {
  if (!str) {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getFieldHTML(
  label: string,
  key: string,
  fieldError: string | null,
  inputType: string = "text",
  options?: { value: string; label: string }[],
) {
  const inputComponent =
    inputType === "select" ? (
      <select
        id={key + "-input"}
        name={key}
        className="p-inputtext p-component"
      >
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : (
      <InputText
        autoComplete={key}
        id={key + "-input"}
        name={key}
        type={inputType}
      />
    );

  return (
    <div className="p-field">
      <label htmlFor={key + "-input"} className="p-d-block text-boldish">
        {label}
      </label>
      {inputComponent}
      {fieldError ? (
        <p className="form-validation-error" role="alert" id={key + "-error"}>
          {fieldError}
        </p>
      ) : null}
    </div>
  );
}

export function convertDateToSQLFormat(dateString?: string) {
  try {
    if (!dateString) return undefined;

    const parts = dateString.split("/");
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    return `${year}-${month}-${day}`;
  } catch (e) {
    return undefined;
  }
}

export const showSuccess = (
  toast: MutableRefObject<Toast | null>,
  t: TFunction,
  message: string,
) => {
  // toast.current?.show({
  //   severity: "success",
  //   summary: t("success", { ns: ["common", "parametre"] }),
  //   detail: t(message, { ns: ["common", "parametre"] }),
  //   life: 3000,
  // });
};

export const showFail = (
  toast: MutableRefObject<Toast | null>,
  t: TFunction,
  message: string | string[],
) => {
  const msg = Array.isArray(message)
    ? message.map((m) => (
        <div key={m}>{t(m, { ns: ["common", "parametre", "error"] })}</div>
      ))
    : t(message, { ns: ["common", "parametre", "error"] });
  toast.current?.show({
    severity: "error",
    summary: t("erreur", { ns: ["common", "parametre", "error"] }),
    detail: msg,
    life: 6000,
  });
};

export const countDecimals = (value: string | number) => {
  if (typeof value == "number") value = value.toString();
  const split = value.split(".");

  if (!value.includes(".") || split.length == 1) return 0;
  return split[1].length || 0;
};
